<template>
  <div class="pads" v-if="!$store.state.loading">
    <div class="cover" data-aos="fade-up" data-aos-duration="1000">
      <img :src="images[0]" alt="CarboBrake Pads"/>
    </div>
    <h1 data-aos="zoom-in" data-aos-duration="1000" v-html="$t('pads-title')"></h1>

    <div class="section">
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('pads-section1-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('pads-section1-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div class="section-image" data-aos="fade-up" data-aos-duration="1000"><img :src="images[1]" alt="CarboBrake Pads"/></div>
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('pads-section2-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('pads-section2-paragraph')"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [require('../assets/pads_cover.jpg'),
        require('../assets/pads2.jpg')
      ]
    }
  },
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          //simulate resize for scrolling transitions
          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
      })
    })
  }
}
</script>

<style lang="scss"></style>
